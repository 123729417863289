import React, {useState, useMemo, useCallback} from 'react';

import {cloneDeep} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {blobToBase64} from 'client/services/blobToBase64';
import {checkForPHPScripts} from 'client/services/checkForPHPScripts';
import {uid} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import {selectOperation, selectClientInterface} from 'client/ducks/operations/selectors';
import {createMediaStorage, updateMediaStorage} from 'client/ducks/templates/actions';
import {selectUser} from 'client/ducks/user/selectors';

import {getAppLanguage, MEDIA_MIME_TYPES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import {FONT_FAMILY_OPTIONS} from 'client/common/text-editor/constants';
import {UiId} from 'client/common/ui-id';

import {DEFAULT_DEVICE_OPTIONS} from 'client/components/diy-operation/controls/lang-device-control';
import {
  ANSWER_KEY_MAP,
  INPUT_TYPES,
  SPECIAL_SELECTOR_TYPES,
} from 'client/components/diy-operation/modals/diy-customization-modal/constants';
import {CUSTOM_INPUTS} from 'client/components/diy-operation/modals/diy-customization-modal/constants';
import {
  getFormItemValue,
  getImageProperties,
  roundValue,
  getMaxValue,
  getRatio,
  getDefaultScreenFormat,
  hasRightFormat,
  hasDefaultStyleTag,
  removeTags,
  toFile,
} from 'client/components/diy-operation/modals/diy-customization-modal/helpers';
import nativeValidate from 'client/components/diy-operation/modals/diy-customization-modal/nativeValidate';
import useClientFonts from 'client/components/diy-operation/modals/diy-customization-modal/useClientFonts';
import {createChecks, validate} from 'client/components/diy-operation/modals/diy-customization-modal/validate';
import DiyOpAccessLevelPopover from 'client/components/diy-operation/popovers/diy-op-access-level-popover';
import {TranslationJsx} from 'client/models/language/types';

import {setValueForAllAnswers, langDeviceSetting, getContainerHeight, getFileRequirements} from './helpers';

import {useFormElementDisabilityCheck} from '../../useFormElementVisibleCheck';
import FormItemField from '../form-item-field';

import './form-item.scss';

const DEFAULT_LABELS = {
  title: '',
  title_more: '',
  comments: '',
};
const BYTES_PER_MBYTE = 1000000;
const DEFAULT_LINE_HEIGHT = 14 * 1.2; // 14 and 1.2 are the textarea default font-size and line-height

const b = bem('form-item');

const FormItem = (props) => {
  const {
    formId,
    formItem,
    setFormData,
    setFormAccessLevel,
    checkFormItem,
    templateId,
    access,
    uiId,
    variables,
    isAccessGranted = false,
  } = props;
  const {
    id: formItemId,
    display_icon,
    display_image_format,
    display_height,
    display_nlines,
    display_type,
    display_width,
    display_horizontal_position = 'center',
    display_vertical_position = 'center',
    display_no_default_color,
    display_styling,
    form_labels,
    form_choices,
    item_type,
    name,
    media_storage_item,
    template_answers,
    display_multi,
    check_mandatory,
    language_unique_mode,
    screen_format_unique_mode,
    special_selector,
    no_image_possible,
    display_variables,
  } = formItem;

  const translations = useLanguage('DIY_OPERATION.MODALS.CONFIGURATION_MODAL');
  const dispatch = useDispatch();
  const {id: userId, locale} = useSelector(selectUser);
  const operation = useSelector(selectOperation);
  const [loading, toggleLoading] = useToggle(false);
  const {fontNames = []} = useClientFonts();
  const currentInterface = useSelector(selectClientInterface);
  const defaultLanguage = currentInterface.default_language;

  const [showMessage, setShowMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const icon = media_storage_item?.media_storage?.url || display_icon || null;
  const labels = form_labels?.length ? form_labels?.find((item) => item.language === getAppLanguage()) : DEFAULT_LABELS;
  const title = labels?.title;
  const description = labels?.title_more;
  const comment = labels?.comments;
  const isToggle = display_type === INPUT_TYPES.TOGGLE;
  const fontFamilySelectOptions = useMemo(
    () =>
      [...fontNames.sort(), ...FONT_FAMILY_OPTIONS].map((family) => ({
        value: family,
        label: <span style={{fontFamily: family}}>{family}</span>,
      })),
    [fontNames],
  );

  const {devices, languages} = useMemo(() => {
    let screenFormats = [];
    let langs = null;
    if (display_type === INPUT_TYPES.IMAGE) {
      screenFormats = DEFAULT_DEVICE_OPTIONS.filter(
        (format) =>
          currentInterface?.screen_formats?.includes(format.value) &&
          formItem?.display_image_format?.includes(format.value),
      );
    }
    if (display_type === INPUT_TYPES.FIELD || display_type === INPUT_TYPES.IMAGE) {
      langs = operation?.languages.map((language) => ({
        label: `${language[0].toUpperCase()}${language.slice(1)}`,
        value: language.toLowerCase(),
      }));
    }
    return {devices: screenFormats, languages: langs};
  }, [currentInterface?.screen_formats, display_type, operation?.languages, formItem?.display_image_format]);

  const [lang, setLang] = useState(langDeviceSetting(language_unique_mode, defaultLanguage || locale));
  const [device, setDevice] = useState(langDeviceSetting(screen_format_unique_mode, getDefaultScreenFormat(devices)));

  const errors = useMemo(() => {
    return createChecks(formItem, {
      ...labels,
      error_mandatory: translations.MANDATORY_FIELD_ERROR,
      error_default: translations.DEFAULT_FIELD_ERROR,
    });
  }, [formItem, labels, translations]);

  const {disabled: isDisabledGlobaly} = useFormElementDisabilityCheck();
  const {disabled} = checkFormItem(formItem, {lang, device});

  const {value, templateAnswer} = useMemo(
    () =>
      getFormItemValue(template_answers, form_choices, {
        type: display_type,
        inputType: item_type,
        language: lang,
        templateId: templateId,
        display_multi,
        special_selector,
      }),
    [display_type, form_choices, item_type, lang, templateId, template_answers, display_multi, special_selector],
  );

  const getFieldProps = () => {
    let params = {};
    switch (display_type) {
      case INPUT_TYPES.TOGGLE:
        params.label = labels?.unit || '';
        params.labelPosition = 'right';
        break;

      case INPUT_TYPES.FIELD:
        if (item_type === 'number') {
          params.type = 'number';
        } else {
          params = {
            type: 'text',
            key: lang,
            displayVariables: display_variables,
            variables: variables,
            isLinesLimited: true,
            displayStyling: display_styling,
            defaultStyle:
              value.find((item) =>
                CUSTOM_INPUTS.includes(lang) ? item.language === defaultLanguage : item.language === lang,
              )?.default_style || false,
          };

          if (item_type === 'date') {
            params.type = 'date';
            params.placeholder = translations.DATE_PLACEHOLDER;
          }

          if (!display_width || display_width > 400) {
            params.width = '100%';
          } else if (display_width < 50) {
            params.width = 50;
          } else {
            params.width = +display_width;
          }

          if (!display_nlines || display_nlines === 1) {
            params.rows = 1;
            params.height = 40;
          } else if (display_nlines < 5) {
            params.rows = +display_nlines;
            params.height = display_nlines * DEFAULT_LINE_HEIGHT + 20; // 20 is a sum of textarea vertical paddings
          } else {
            params.height = 5 * DEFAULT_LINE_HEIGHT + 20;
            params.rows = 5;
          }
        }
        break;

      case INPUT_TYPES.COLOR:
        params.defaultColor = templateAnswer?.default_color;
        params.colorRequired = display_no_default_color;
        break;

      case INPUT_TYPES.DROPDOWN:
        params = {
          options:
            special_selector === SPECIAL_SELECTOR_TYPES.FONT
              ? fontFamilySelectOptions
              : form_choices?.map(({form_labels: formLabels, id, key}) => {
                  const formLabel = formLabels.find(({language}) => language === lang);
                  return {
                    id,
                    label: formLabel?.title || key,
                    value: key,
                  };
                }),
          multi: display_multi,
        };
        break;

      case INPUT_TYPES.CAROUSEL:
        params = {
          images: form_choices.map(({form_labels: formLabels, key}) => {
            const formLabel = formLabels.find(({language}) => language === lang);
            const {url: image} = formLabel?.media_storage_item?.media_storage || {id: 0, url: ''};
            return {
              id: key,
              image,
              label: formLabel.title,
            };
          }),
          multiselect: display_multi,
        };
        break;

      case INPUT_TYPES.IMAGE:
        const height = getContainerHeight(display_height);

        let formats = '';

        if (errors.checks?.check_error_format) {
          formats = errors.checks?.check_error_format.join(',').replace(/(\w+)/g, '.$1');
        }

        params.display_image_format = display_image_format;
        params.loading = loading;
        params.keepRatio = true;
        params.height = height;
        params.horAlign = display_horizontal_position || 'center';
        params.verAlign = display_vertical_position || 'center';
        params.width = display_width || '';
        params.key = `${lang}-${device}`;
        params.imageStyle = {
          maxHeight: `${height}px`,
        };
        params.acceptFormats = formats;
        params.requirements = getFileRequirements(formItem, device);
        params.no_image_possible = no_image_possible;
        params.device = device;
        break;

      default:
        break;
    }
    return params;
  };

  const handleMediaStorage = useCallback(
    async (mediaId, data) => {
      let mediaStorage;
      toggleLoading();
      if (mediaId) {
        mediaStorage = await dispatch(updateMediaStorage(mediaId, data));
      } else {
        mediaStorage = await dispatch(createMediaStorage(data));
      }
      toggleLoading();
      return mediaStorage;
    },
    [dispatch, toggleLoading],
  );

  const handleChange = useCallback(
    async (val, valLang, valDevice, skipValidation) => {
      let answer = {
        form_item_id: formItemId,
        template_id: templateId,
        display_type,
        special_selector,
        ...(!!templateAnswer && templateAnswer),
      };
      let message;
      let params = {};
      let errMsg;
      const key = special_selector === SPECIAL_SELECTOR_TYPES.NONE ? ANSWER_KEY_MAP[display_type] : 'string_field';

      switch (display_type) {
        case INPUT_TYPES.FIELD:
          let input = typeof val === 'object' ? val.target.value : val;

          let path = 'number_field';
          if (item_type === 'string') {
            path = key;
          } else if (item_type === 'date') {
            path = 'date_field';
          }

          answer[path] = item_type === 'date' ? moment(input, 'DD/MM/YYYY').toISOString() : input;
          answer[path] = item_type === 'number' && input && !isNaN(+input) ? +input : input;

          if (item_type === 'string') {
            answer.default_style = hasDefaultStyleTag(answer[path]);

            if (CUSTOM_INPUTS.includes(valLang)) {
              answer = languages.map((item) => {
                const currentValue = value.find((elem) => {
                  return elem.language === item.value;
                });

                return {...answer, language: item.value, ...(currentValue && {id: currentValue.id})};
              });
            } else {
              const currentValue = value.find((elem) => elem.language === valLang);
              if (currentValue?.id) {
                answer.id = currentValue?.id;
              }
              answer.language = valLang;
            }
          }

          if (item_type === 'number' || item_type === 'date') {
            answer = languages.map((item) => {
              const currentValue = value.find((elem) => {
                return elem.language === item.value;
              });
              return {...answer, language: item.value, ...(currentValue && {id: currentValue.id})};
            });
          }

          input = removeTags(input);
          message = !skipValidation && validate(input, errors);
          if (!message && !skipValidation) {
            message = nativeValidate(input, item_type, translations.NATIVE_ERRORS);
          }

          break;

        case INPUT_TYPES.COLOR:
          if (val) {
            const color = val.replace('#', '').slice(0, 8);
            answer[key] = color;
            answer.default_color = false;
            message = !skipValidation && validate(color, errors);
          } else {
            answer[key] = '';
            answer.default_color = true;
          }
          break;

        case INPUT_TYPES.IMAGE:
          const uploadInput = {
            ...answer,
          };

          if (val && !hasRightFormat(val, errors.checks?.check_error_format)) {
            if (labels.error_format !== '') {
              setShowMessage([labels.error_format]);
            } else {
              setShowMessage([translations.DEFAULT_FIELD_ERROR]);
            }
            return;
          }

          if (val) {
            const info = typeof val === 'string' ? val : await blobToBase64(val);
            const media = typeof val === 'string' ? await toFile(val) : val;

            if (
              [MEDIA_MIME_TYPES.html, MEDIA_MIME_TYPES.mp3, MEDIA_MIME_TYPES.mp4, MEDIA_MIME_TYPES.pdf].includes(
                media?.type,
              )
            ) {
              const data = await blobToBase64(media);

              // now we check only size and mandatory fields
              const mediaSizeChecks = {
                checks: {
                  check_error_size: CUSTOM_INPUTS.includes(valDevice)
                    ? getMaxValue(errors.checks?.check_error_size)
                    : errors.checks?.check_error_size?.[valDevice],
                  check_mandatory: errors.checks?.check_mandatory ? errors.checks?.check_mandatory : '',
                  check_scripts: media?.type === MEDIA_MIME_TYPES.html ? await checkForPHPScripts(media) : '',
                },
                messages: {
                  check_error_size: errors.messages?.check_error_size,
                  check_mandatory: translations.MANDATORY_FIELD_ERROR,
                  error_default: translations.DEFAULT_FIELD_ERROR,
                  check_scripts: translations.MEDIA_MIME_TYPES_HTML_SCRIPTS_ERROR,
                },
              };

              errMsg =
                !skipValidation && validate({size: roundValue(media.size / BYTES_PER_MBYTE, 3)}, mediaSizeChecks);

              if (errMsg) {
                setShowMessage(errMsg);
                return;
              }

              uploadInput.data = {
                media_storage: {
                  file: {
                    data: data,
                    filename: media.name || uid(),
                  },
                  client_user_id: userId,
                },
              };

              const mediaId = answer?.media_storage_item?.media_storage_id;
              const mediaStorage = await handleMediaStorage(mediaId, uploadInput.data);

              uploadInput.media_storage_item = {
                media_storage_id: mediaStorage?.payload?.media_storage?.id,
                media_storage: {
                  ...mediaStorage?.payload?.media_storage,
                  id: answer?.media_storage_item?.media_storage?.id,
                  client_user_id: userId,
                },
              };
            } else {
              toggleLoading();
              const imageProps = await getImageProperties(info).finally(toggleLoading);
              const imageSizeChecks = {
                checks: {
                  check_error_height: CUSTOM_INPUTS.includes(valDevice)
                    ? getMaxValue(errors.checks?.check_error_height)
                    : errors.checks?.check_error_height?.[valDevice],
                  check_error_width: CUSTOM_INPUTS.includes(valDevice)
                    ? getMaxValue(errors.checks?.check_error_width)
                    : errors.checks?.check_error_width?.[valDevice],
                  check_error_ratio: CUSTOM_INPUTS.includes(valDevice)
                    ? getRatio(errors.checks?.check_error_ratio)
                    : errors.checks?.check_error_ratio?.[valDevice],
                  check_error_size: CUSTOM_INPUTS.includes(valDevice)
                    ? getMaxValue(errors.checks?.check_error_size)
                    : errors.checks?.check_error_size?.[valDevice],
                  check_mandatory: errors.checks?.check_mandatory ? errors.checks?.check_mandatory : '',
                },
                messages: {
                  check_error_height: errors.messages?.check_error_height,
                  check_error_width: errors.messages?.check_error_width,
                  check_error_ratio: errors.messages?.check_error_ratio,
                  check_error_size: errors.messages?.check_error_size,
                  check_mandatory: translations.MANDATORY_FIELD_ERROR,
                  error_default: translations.DEFAULT_FIELD_ERROR,
                },
              };
              errMsg =
                !skipValidation &&
                validate(
                  {
                    ...imageProps,
                    size: roundValue(imageProps.size / BYTES_PER_MBYTE, 3),
                  },
                  imageSizeChecks,
                );
              if (errMsg) {
                setShowMessage(errMsg);
                return;
              }

              uploadInput.media_storage_item = {
                ...answer?.media_storage_item,
                media_storage: {
                  file: {
                    data: imageProps.data,
                    filename: val.name || uid(),
                  },
                  url: imageProps.data,
                  client_user_id: userId,
                  ...(answer?.media_storage_item?.media_storage?.id && {
                    id: answer.media_storage_item.media_storage.id,
                  }),
                },
              };
            }
          } else {
            uploadInput.media_storage_item = {_destroy: true};
          }

          // check if lang and screen_format set to precise value
          if (!CUSTOM_INPUTS.includes(valLang) && !CUSTOM_INPUTS.includes(valDevice)) {
            // find existing template answer
            const currentValue = value.find((elem) => elem.language === valLang && elem.screen_format === valDevice);
            if (currentValue) {
              uploadInput.id = currentValue?.id;
              uploadInput.screen_format = currentValue?.screen_format;
              uploadInput.language = currentValue?.language;
            } else {
              uploadInput.screen_format = valDevice;
              uploadInput.language = valLang;
            }
            answer = {
              ...answer,
              ...cloneDeep(uploadInput),
            };
          } else {
            params.language_unique_mode = CUSTOM_INPUTS.includes(valLang) ? valLang : null;
            params.screen_format_unique_mode = CUSTOM_INPUTS.includes(valDevice) ? valDevice : null;
            answer = setValueForAllAnswers(uploadInput, {lang: valLang, languages, device: valDevice, devices, value});
          }
          message = errMsg;
          break;

        case INPUT_TYPES.TOGGLE:
          const toggle = val.target.checked;
          answer[key] = toggle;
          message = !skipValidation && validate(toggle, errors);
          break;

        case INPUT_TYPES.DROPDOWN:
          let dropdownValue;
          if (special_selector !== SPECIAL_SELECTOR_TYPES.NONE) {
            if (display_multi) {
              answer = val.map((item) => ({
                ...answer,
                [key]: item.value,
              }));
            } else {
              answer[key] = val.value;
            }

            break;
          }
          if (display_multi) {
            if (!val.length) {
              return;
            }
            dropdownValue = val.map((item) => ({
              form_choice_id: item?.id || item?.form_choice_id,
              value: item.value,
              ...(answer?.id && {template_answer_id: answer.id}),
            }));
          } else {
            dropdownValue = [
              {
                form_choice_id: val.id,
                value: val.value,
                ...(answer?.id && {template_answer_id: answer.id}),
              },
            ];
          }
          answer[key] = dropdownValue;
          break;

        case INPUT_TYPES.CAROUSEL:
          if (!val.length) {
            return;
          }
          const carouselValue = val.map((elem) => ({
            form_choice_id: form_choices?.find((choice) => choice.key === elem)?.id,
            value: elem,
            ...(answer?.id && {template_answer_id: answer.id}),
          }));
          answer[key] = [...carouselValue];
          break;

        default:
          break;
      }

      if (!skipValidation) {
        setErrorMessage(message);
      }

      setFormData({value: answer, formItemId}, message, params);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      templateId,
      formItemId,
      display_type,
      templateAnswer,
      setFormData,
      item_type,
      languages,
      devices,
      errors,
      display_multi,
      value,
      userId,
      form_choices,
      handleMediaStorage,
      translations.NATIVE_ERRORS,
    ],
  );

  const langSelection = {};
  const deviceSelection = {};
  if (
    display_type === INPUT_TYPES.IMAGE ||
    (display_type === INPUT_TYPES.FIELD && item_type !== 'number' && item_type !== 'date')
  ) {
    langSelection.lang = lang;
    langSelection.defaultLanguage = defaultLanguage || locale;
    langSelection.languages = languages;
    langSelection.setLang = setLang;
  }

  if (display_type === INPUT_TYPES.FIELD && item_type === 'number') {
    langSelection.lang = lang;
    langSelection.defaultLanguage = defaultLanguage || locale;
  }

  if (display_type === INPUT_TYPES.IMAGE) {
    deviceSelection.device = device;
    deviceSelection.devices = devices;
    deviceSelection.defaultDevice = getDefaultScreenFormat(devices);
    deviceSelection.setDevice = setDevice;
  }

  return (
    <>
      <div className={b({'is-toggle': isToggle})}>
        <div className={b('header')}>
          <div className={b('title')}>
            {icon && !isToggle && (
              <span className={b('title-icon')}>
                <img src={icon} alt="" className={b('icon')} />
              </span>
            )}
            <span className={b('title-text')}>
              <UiId content={title} hash={uiId} />
            </span>
          </div>
          <div className={b('header-info')}>
            {check_mandatory && <p className={b('required')}>{translations.REQUIRED}</p>}
            {isAccessGranted && access && (
              <DiyOpAccessLevelPopover
                access={access}
                title={translations.ACCESS_LEVEL_TITLE}
                onChange={(val) => {
                  setFormAccessLevel?.({value: val, formItemId: formItem.id, type: 'formItem'});
                }}
              />
            )}
          </div>
        </div>
        {description && (
          <div className={b('description')}>
            <span>{description}</span>
          </div>
        )}
        <FormItemField
          name={name}
          disabled={disabled || isDisabledGlobaly}
          {...langSelection}
          {...deviceSelection}
          formItemId={formItemId}
          formId={formId}
          type={display_type}
          value={value}
          fieldProps={getFieldProps()}
          onChange={handleChange}
          errorMessage={errorMessage}
          langMode={language_unique_mode}
          deviceMode={screen_format_unique_mode}
          setFormData={setFormData}
        />
        {comment && (
          <div className={b('comment')}>
            <i>{comment}</i>
          </div>
        )}
      </div>
      <ConfirmationModal
        show={Boolean(showMessage)}
        onConfirm={() => setShowMessage(null)}
        onClose={() => setShowMessage(null)}
        messageClassName={b('message-container')}
        message={
          showMessage &&
          showMessage.map((item, idx) => (
            <span className={b('message', {bullet: showMessage?.length > 1})} key={idx}>
              {item}
            </span>
          ))
        }
        clientSide={true}
        buttonConfirmColor="devices"
        title={translations.UPLOAD_ERROR}
        buttonConfirm={{
          label: 'Ok',
        }}
      />
    </>
  );
};

FormItem.propTypes = {
  templateId: PropTypes.number.isRequired,
  formId: PropTypes.number.isRequired,
  formItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    display_icon: PropTypes.string,
    display_width: PropTypes.string,
    display_height: PropTypes.string,
    display_image_format: PropTypes.array,
    display_horizontal_position: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    display_vertical_position: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    display_nlines: PropTypes.number,
    display_multi: PropTypes.bool,
    display_no_default_color: PropTypes.bool,
    check_mandatory: PropTypes.bool,
    language_unique_mode: PropTypes.string,
    screen_format_unique_mode: PropTypes.string,
    item_type: PropTypes.string,
    form_labels: PropTypes.arrayOf(
      PropTypes.shape({
        label: TranslationJsx,
        title: TranslationJsx,
        comments: TranslationJsx,
      }),
    ).isRequired,
    form_choices: PropTypes.array,
    media_storage_item: PropTypes.shape({
      media_storage: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    description: TranslationJsx,
    display_type: PropTypes.string.isRequired,
    template_answers: PropTypes.array,
  }),
  setFormData: PropTypes.func.isRequired,
  setFormAccessLevel: PropTypes.func,
  access: PropTypes.string.isRequired,
  isAccessGranted: PropTypes.bool,
  uiId: PropTypes.string.isRequired,
};

FormItem.defaultProps = {
  formItem: {
    name: '',
    display_icon: '',
    display_width: '',
    display_height: '',
    display_nlines: 1,
    display_multi: false,
    display_no_default_color: false,
    item_type: 'text',
    description: '',
    form_choices: [],
    template_answers: [],
  },
};

export default FormItem;
