import React, {useEffect} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {useHistory, useLocation} from 'react-router-dom';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {getAccessLevel} from 'client/services/cookie-data-source';
import {useLanguage} from 'client/services/hooks';

import Pagination from 'client/common/paginations/pagination';
import SelectDropdown from 'client/common/selects/select-dropdown';

import Typography from 'client/components/common/typography';

import NewOperationCard from 'client/components/operations-client/components/cards/new-operation-card';
import OperationCard from 'client/components/operations-client/components/cards/operation-card';
import DiyOperationModal from 'client/components/operations-client/components/modals/diy-operation-modal';
import {ACCESS_LEVEL} from 'client/models/common/constants';
import {TranslationJsx} from 'client/models/language/types';

import cssModule from './operation-cards.module.scss';

const weightByLevel = {
  [ACCESS_LEVEL.CLIENT_ADMIN]: 3,
  [ACCESS_LEVEL.NATIONAL]: 3,
  [ACCESS_LEVEL.REGION]: 2,
  [ACCESS_LEVEL.STORE]: 1,
};

const tags = {
  [ACCESS_LEVEL.CLIENT_ADMIN]: ACCESS_LEVEL.NATIONAL,
  [ACCESS_LEVEL.NATIONAL]: ACCESS_LEVEL.NATIONAL,
  [ACCESS_LEVEL.REGION]: ACCESS_LEVEL.REGION,
};

const b = bem('operation-cards', {cssModule});

const OperationsCards = (props) => {
  const {
    data,
    title,
    sort,
    onSelectOperation,
    selectedOperationId,
    onPageChange,
    currentPage,
    totalPages,
    onSortChange,
    canCreateOperation,
    isWide,
    headerId,
    className,
    client,
  } = props;

  const lang = useLanguage('OPERATIONS');
  const [isDiyOperationModalVisible, toggleDiyOperationModalVisibility] = useToggle(false);

  const {client_network} = client;
  const userAccessLevel = getAccessLevel();
  const isConfigurationBlock = canCreateOperation;
  const history = useHistory();

  const location = useLocation();
  const presetTemplateId = location.state?.templateId;

  useEffect(() => {
    if (presetTemplateId) {
      toggleDiyOperationModalVisibility(true);
    }
  }, [presetTemplateId, toggleDiyOperationModalVisibility]);

  const handleCloseOperationModal = () => {
    toggleDiyOperationModalVisibility();
    history.replace(location.pathname + location.search, {state: null});
  };

  return (
    <section className={cn(b(), className)}>
      {isConfigurationBlock && isWide && isDiyOperationModalVisible && (
        <DiyOperationModal show onClose={handleCloseOperationModal} client={client} />
      )}
      <div className={b('header-wrap')}>
        {title && isWide && (
          <header className={b('header')}>
            <Typography variant="title-h1" className={b('title')} id={headerId}>
              {title}
            </Typography>
          </header>
        )}
        {isWide && (
          <div className={b('dropdown')}>
            <span className={b('dropdown-text')}>{lang.OPERATION_SORT}</span>
            <SelectDropdown
              type="sort-by"
              options={[
                {value: 'from', label: lang.START_DATE_COLUMN},
                {value: 'to', label: lang.END_DATE_COLUMN},
              ]}
              className={b('dropdown-input')}
              onChange={onSortChange}
              value={sort}
              simpleValue
            />
          </div>
        )}
      </div>

      <div className={b('content')}>
        {data.map((operation) => {
          const {id, membership, available_membership} = operation;
          // available_membership - for case when operation is attributed to agency user
          const {places, regions, access_level: operationAccessLevel} = membership || available_membership || {};

          const additionalProps = {};

          const isSubLevelOp = weightByLevel[userAccessLevel] > weightByLevel[operationAccessLevel];
          const isSameLevelOp = weightByLevel[userAccessLevel] === weightByLevel[operationAccessLevel];

          if (client_network) {
            const isUpperLevelOp = weightByLevel[userAccessLevel] < weightByLevel[operationAccessLevel];

            if (isSubLevelOp && (regions || places)) {
              // show regions in case both are present
              const levelsToShow = regions.length ? regions : places;

              additionalProps.additionalInfo = levelsToShow.map(({name}) => name).join(', ');
            } else if (isUpperLevelOp) {
              additionalProps.tag = tags[operationAccessLevel];
            }
          }

          if (isSameLevelOp || isConfigurationBlock) {
            additionalProps.redirectToConfigTab = true;
          }

          return (
            <OperationCard
              key={id}
              className={b('card')}
              operation={operation}
              onSelectOperation={onSelectOperation}
              isSelected={+selectedOperationId === id}
              isWide={isWide}
              clientName={operation.client_id === client.id ? null : operation?.client?.name}
              {...additionalProps}
            />
          );
        })}
        {isConfigurationBlock && (
          <NewOperationCard
            className={b('card')}
            text={lang.NEW_OPERATION}
            onClick={toggleDiyOperationModalVisibility}
          />
        )}
      </div>

      {totalPages > 1 && (
        <Pagination totalPages={totalPages} maxButtons={3} currentPage={currentPage} onClick={onPageChange} />
      )}
    </section>
  );
};

OperationsCards.defaultProps = {
  title: '',
  data: [],
  selectedOperationId: null,
  canCreateOperation: null,
  isWide: true,
  className: '',
};

OperationsCards.propTypes = {
  title: TranslationJsx,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      operation_image: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  ),
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  sort: PropTypes.oneOf(['from', 'to']).isRequired,
  onSelectOperation: PropTypes.func.isRequired,
  selectedOperationId: PropTypes.string,
  canCreateOperation: PropTypes.bool,
  isWide: PropTypes.bool,
  className: PropTypes.string,
  headerId: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
};

export default OperationsCards;
