import React, {useEffect, useMemo, useState} from 'react';

import cn from 'classnames';
import filter from 'lodash/filter';
import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';
import {reduxForm} from 'redux-form';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm from 'client/services/hooks/use-redux-form';

import {linkDevice, patchDeviceAction} from 'client/ducks/devices/actions';
import {selectOperation} from 'client/ducks/operations/selectors';

import AppButton from 'client/common/buttons';
import {FieldWrap, SelectField} from 'client/common/fields';
import DatetimePickerField from 'client/common/fields/datetime-picker-field';
import {useToast} from 'client/common/hooks/useToast';
import Icon from 'client/common/icon';
import Modal from 'client/common/modals/modal';

import Spinner from 'client/components/common/spinner';

import {getLanguages} from 'client/components/diy-operation/blocks/diy-op-main-block/diy-op-main-block-langs/helpers';
import {INTERACTION_STATUSES} from 'client/models/interactions/constants';
import {isOperationStarted} from 'client/models/operations/helpers';

import DeviceDiyParametersModalInteractions from './device-diy-parameters-modal-interactions';
import DeviceDiyParametersModalPrizes from './device-diy-parameters-modal-prizes';
import useFetchPrizes from './hooks/use-fetch-prizes';
import initial from './initial';
import mapping from './mapping';
import validate from './validate';

import {getCurrentInteraction} from '../../lists/devices-diy-list/devices-diy-list-table/helpers';

import './device-diy-parameters-modal.scss';

const b = bem('device-diy-parameters-modal');

const DeviceDiyParametersModal = React.memo((props) => {
  const {onClose, onSave, device, handleSubmit, disabled} = props;
  const lang = useLanguage('CLIENT_DEVICES.DIY_PARAMETERS_MODAL');
  const langLangs = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_MAIN_BLOCK.DIY_OP_MAIN_BLOCK_LANGS');
  const langCommon = useLanguage('COMMON');
  const {initialize, errors, formValues} = useReduxForm(DeviceDiyParametersModal.formName);
  const dispatch = useDispatch();
  const operation = useSelector(selectOperation);
  const [loading, setLoading] = useState(false);
  const {data: prizes, loading: loadingPrizes} = useFetchPrizes(formValues?.devicePrizes);
  const {appendToastNotification} = useToast();

  const isOpStarted = isOperationStarted(operation);

  const valid = !Object.values(errors).filter(Boolean).length;

  const valuesLanguages = getLanguages(langLangs);

  const currentInteraction = useMemo(() => getCurrentInteraction(device, operation), [device, operation]);
  const interactionIsStarted = currentInteraction && currentInteraction?.current_status !== INTERACTION_STATUSES.NEW;

  const linkedInteractions = useMemo(() => {
    return filter(device?.interactions, (i) => i.id !== currentInteraction?.id);
  }, [currentInteraction?.id, device?.interactions]);

  useEffect(() => {
    if (!loadingPrizes) {
      initialize(initial(device, prizes, operation));
    }
    return () => initialize({});
  }, [initialize, loadingPrizes, prizes, operation, currentInteraction, device]);

  const requestMethod = async () => {
    const body = await mapping(formValues, {interactionId: currentInteraction?.id, deviceId: device?.id});

    if (currentInteraction?.id) {
      return dispatch(patchDeviceAction(device.id, body));
    }
    return dispatch(linkDevice(operation.id, body));
  };

  const onSubmit = async () => {
    setLoading(true);
    const response = await requestMethod();

    if (response?.payload?.device?.token_list_errors?.length) {
      appendToastNotification({
        type: 'warning',
        description: lang.DEVICE_TOKEN_ERROR,
      });
    }

    if (response.error) {
      const responseErrors = response.payload?.response?.errors;
      let exceptionMessage = response.payload?.response?.exception;
      exceptionMessage = typeof exceptionMessage === 'string' ? exceptionMessage : '';
      appendToastNotification({
        type: 'error',
        description: exceptionMessage || responseErrors?.base?.[0] || responseErrors?.device?.[0],
      });
    } else {
      onSave();
      appendToastNotification({
        type: 'success',
        description: lang.DEVICE_SAVED_SUCCESSED,
      });
    }
    setLoading(false);
  };

  const languageOptions = useMemo(() => {
    return operation?.languages.map((language) => ({
      label: (
        <span className={b('language-option')}>
          <Icon name={`flag-${language}`} />
          {valuesLanguages[language]}
        </span>
      ),
      value: language,
    }));
  }, [valuesLanguages, operation?.languages]);

  return (
    <Modal title={lang.POPUP_TITLE} show dialogClassName={cn(b())} onClose={onClose}>
      {loadingPrizes ? (
        <Spinner centered />
      ) : (
        <>
          {!!linkedInteractions?.length && (
            <DeviceDiyParametersModalInteractions device={device} interactions={linkedInteractions} />
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <p className={b('text-information')}>
              {lang.CHOOSE_PERIOD.split('<OperationName>')[0]}
              <strong>{operation?.name}</strong>
              {lang.CHOOSE_PERIOD.split('<OperationName>')[1]}
            </p>
            <FieldWrap className={b('period')}>
              <div className={b('row', ['period'])}>
                {!interactionIsStarted && (
                  <>
                    <DatetimePickerField
                      timePattern="HH:mm:00"
                      name="from"
                      label={lang.DATE_FROM_LABEL}
                      className={b('field', ['date'])}
                      disabled={disabled}
                    />
                    <Icon name="arrow" className={b('period-arrow')} />
                  </>
                )}
                <DatetimePickerField
                  timePattern="HH:mm:00"
                  name="to"
                  label={lang.DATE_TO_LABEL}
                  className={b('field', ['date'])}
                  disabled={disabled}
                />
              </div>
              {errors.period && <p className={b('error-message')}>{lang.ERRORS[errors.period]}</p>}
            </FieldWrap>
            <SelectField
              simpleValue
              name="default_language"
              label={lang.DEFAULT_LANGUAGE_LABEL}
              className={b('field', ['language'])}
              options={languageOptions}
              disabled={disabled}
            />

            {currentInteraction && (
              <DeviceDiyParametersModalPrizes
                className={b('device-prizes')}
                disabled={disabled}
                isOperationStarted={isOpStarted}
              />
            )}
            <div className={b('row', ['buttons'])}>
              <AppButton label={langCommon.CANCEL} transparent className={b('cancel-button')} onClick={onClose} />
              <AppButton
                label={langCommon.OK}
                className={b('submit-button')}
                submit
                loading={loading}
                disabled={!valid || disabled}
              />
            </div>
          </form>
        </>
      )}
    </Modal>
  );
});

DeviceDiyParametersModal.formName = 'DEVICE_DIY_PARAMETERS_MODAL';
DeviceDiyParametersModal.displayName = 'DeviceDiyParametersModal';

DeviceDiyParametersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  device: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    interactions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        default_language: PropTypes.string,
        from: PropTypes.string,
        to: PropTypes.string,
      }),
    ).isRequired,
  }),
  onSave: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

DeviceDiyParametersModal.defaultProps = {
  device: null,
};

export default connect((state) => ({
  operation: selectOperation(state),
}))(
  reduxForm({
    form: DeviceDiyParametersModal.formName,
    validate,
  })(DeviceDiyParametersModal),
);
